<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="less" scoped>
// #app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // min-width: 1200px;
  // width: 100%;
  // height: 100%;
  // box-sizing: content-box;
  // color: #2c3e50;
// }
</style>
