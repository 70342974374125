import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/menu/menu.vue'),
    redirect: '/menu/userManage',
    children: [
      { path: '/menu/userManage',
        name: 'userManage',
        meta: {
          title: '用户管理',
          keepAlive: true,
        },
        component: () => import('@/views/userManage/userManage.vue')
      },
      {
        path: '/menu/public',
        name: 'public',
        meta: {
          keepAlive: true,
        },
        component: () => import('@/views/public/public.vue')
      },
      {
        path: '/menu/ebook',
        name: 'ebook',
        component: () => import('@/views/ebook/ebook.vue')
      },
      {
        path: '/menu/studyCard',
        name: 'studyCard',
        component: () => import('@/views/studyCard/studyCard.vue')
      },
      {
        path: '/menu/ranking',
        name: 'ranking',
        component: () => import('@/views/ranking/ranking.vue')
      },
      {
        path: '/menu/counselor',
        name: 'counselor',
        component: () => import('@/views/counselor/counselor.vue')
      },
      {
        path: '/menu/studySign',
        name: 'studySign',
        component: () => import('@/views/studySign/studySign.vue')
      },
      {
        path: '/menu/exercise',
        name: 'exercise',
        component: () => import('@/views/exercise/exercise.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
